'use client';
import Tooltip from '@/components/Tooltip/Tooltip';
import { Avatar as ShadcnAvatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import useAuth from '@/hooks/useAuth';

const Avatar = () => {
    const { user } = useAuth();
    return (
        <Tooltip content={user?.name} className='bg-secondary-foreground'>
            <ShadcnAvatar>
                <AvatarImage src={user?.picture} alt='avatar' />
                <AvatarFallback className='uppercase'>{user?.name?.substring(0, 2)}</AvatarFallback>
            </ShadcnAvatar>
        </Tooltip>
    );
};

export default Avatar;
