import React from 'react';

interface InfinityProps {
    size?: string;
    speed?: string;
    theme: string;
}
const ThreeDotLoading = (props: InfinityProps) => {
    const { size = '65', speed = '1.3', theme } = props;

    return (
        <l-dot-wave
            size={size}
            stroke-length='0.15'
            bg-opacity='0.1'
            speed={speed}
            color={`${theme === 'dark' ? 'white' : 'black'}`}
        />
    );
};

export default ThreeDotLoading;
