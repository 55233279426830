'use client';

import { ReactNode, Suspense, useEffect, useState } from 'react';
import { I18nProviderClient } from 'locales/client';
import { AntdRegistry } from '@ant-design/nextjs-registry';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useEnv from '@/hooks/useEnv';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { type ThemeProviderProps } from 'next-themes/dist/types';
import { Toaster as Sooner } from '@/components/ui/sonner';
import { Toaster } from '@/components/ui/toaster';
import ThreeDotLoading from '@/components/Loaders/ThreeDotLoading';
import Router from 'next/router';
import LocalStorage from '@/constants/LocalStorage';
import { cn } from '@/utils/utils';
import { useLocalStorage, useWindowScroll } from '@mantine/hooks';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { UserProvider, withPageAuthRequired } from '@auth0/nextjs-auth0/client';
import useAuth from '@/hooks/useAuth';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

/** React query config */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {},
        mutations: {},
    },
});
export const themeProps: Omit<ThemeProviderProps, 'children'> = {
    attribute: 'class',
    defaultTheme: 'system',
    enableSystem: true,
    // disableTransitionOnChange: true,
};

interface IProviderProps {
    children: ReactNode;
    locale: string;
    theme?: string;
}
// Wrap children in session provider to handle auth
const Providers = (props: IProviderProps) => {
    const { children, locale, theme = 'light' } = props;
    const { isDevelopment } = useEnv();
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        async function getLoader() {
            const { dotWave, lineWobble } = await import('ldrs');
            dotWave.register();
            lineWobble.register();
        }
        getLoader();
        setIsClient(true);
    }, []);

    if (!isClient) return <FullScLoading theme={theme} />;

    return (
        <Suspense fallback={<FullScLoading theme={theme} />}>
            <QueryClientProvider client={queryClient}>
                <I18nProviderClient locale={locale}>
                    <UserProvider>
                        <AntdRegistry>
                            <ThemeProvider theme={theme}>
                                {children}
                                <Sooner />
                                <Toaster />
                            </ThemeProvider>
                        </AntdRegistry>
                    </UserProvider>
                    {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
                </I18nProviderClient>
            </QueryClientProvider>
        </Suspense>
    );
};
export default Providers;

const ThemeProvider = withPageAuthRequired(
    ({ children, theme }: { children: React.ReactNode; theme: string }) => {
        const { update, isLoading } = useAuth();
        const [, scrollTo] = useWindowScroll();
        const [visible, setVisible] = useState(false);
        const [, setTokenExp] = useLocalStorage({
            key: LocalStorage.TOKEN_EXP,
            defaultValue: dayjs().toString(),
        });
        const tokenExp = localStorage.getItem(LocalStorage.TOKEN_EXP);
        const handleUpdateToken = () => {
            if (!isTokenWithinLastHour(tokenExp)) {
                setTokenExp(dayjs().toString());
                update();
                return;
            }
            return;
        };

        useEffect(() => {
            scrollTo({ x: 0, y: 0 });
            const visibilityHandler = () =>
                document.visibilityState === 'visible' && handleUpdateToken();
            window.addEventListener('visibilitychange', visibilityHandler, false);
            return () => window.removeEventListener('visibilitychange', visibilityHandler, false);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            const start = () => {
                setVisible(true);
            };
            const complete = () => {
                setVisible(false);
            };

            Router.events.on('routeChangeStart', start);
            Router.events.on('routeChangeComplete', complete);
            Router.events.on('routeChangeError', complete);

            return () => {
                Router.events.off('routeChangeStart', start);
                Router.events.off('routeChangeComplete', complete);
                Router.events.off('routeChangeError', complete);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        if (visible) return <FullScLoading theme={theme} />;

        if (isLoading) return <FullScLoading theme={theme} />;
        return (
            <NextThemesProvider
                {...themeProps}
                defaultTheme={theme}
                storageKey={LocalStorage.THEME_KEY}
                themes={['light', 'dark']}>
                {children}
            </NextThemesProvider>
        );
    }
);
export const FullScLoading = ({ theme }: { theme: string }) => {
    return (
        <div
            className={cn(
                `flex-center absolute inset-0 z-50 h-full w-full`,
                theme === 'dark' ? 'bg-black' : 'bg-white'
            )}>
            <ThreeDotLoading theme={theme} />
        </div>
    );
};

const isTokenWithinLastHour = (tokenExp: string): boolean => {
    if (!tokenExp) {
        throw new Error('Token expiration time is missing');
    }

    const tokenExpirationTime = dayjs(tokenExp);
    if (!tokenExpirationTime.isValid()) {
        throw new Error('Invalid token expiration time format');
    }
    const oneHourAgo = dayjs().subtract(1, 'hour');

    return tokenExpirationTime.isBetween(oneHourAgo, dayjs(), 'second');
};
