import {
    Sheet as ShadcnSheet,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '@/components/ui/sheet';
import { cn } from '@/utils/utils';
import { DialogProps } from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

const SHEET_SIDES = ['top', 'right', 'bottom', 'left'] as const;

type SheetSide = (typeof SHEET_SIDES)[number];

interface ISheetProps {
    children?: ReactNode;
    title?: ReactNode;
    description?: ReactNode;
    footer?: ReactNode;
    content: ReactNode;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fit';
    side?: SheetSide;
    sheetProps?: DialogProps;
}

const Sheet = (props: ISheetProps) => {
    const {
        children,
        title,
        description,
        footer,
        content,
        side,
        sheetProps,
        maxWidth = 'fit',
    } = props;
    return (
        <ShadcnSheet {...sheetProps}>
            <SheetTrigger asChild>{children}</SheetTrigger>
            <SheetContent side={side} className={cn(`px-0 !max-w-${maxWidth}`)}>
                {(title || description) && (
                    <SheetHeader>
                        {title && <SheetTitle>{title}</SheetTitle>}
                        {description && <SheetDescription>{description}</SheetDescription>}
                    </SheetHeader>
                )}
                {content}
                <SheetFooter>{footer}</SheetFooter>
            </SheetContent>
        </ShadcnSheet>
    );
};

export default Sheet;
