'use client';
import { createI18nClient } from 'next-international/client';

export const {
    useI18n,
    useScopedI18n,
    I18nProviderClient,
    useChangeLocale,
    useCurrentLocale,
    defineLocale,
} = createI18nClient({
    en: () => import('./translations/en'),
    de: () => import('./translations/de'),
});
