'use client';

import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    ToastViewport,
} from '@/components/ui/toast';
import { useToast } from '@/components/ui/use-toast';
import { FaCheckCircle, FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa';
import { FaCircleXmark } from 'react-icons/fa6';

export function Toaster() {
    const { toasts } = useToast();

    return (
        <ToastProvider>
            {toasts.map(function ({ id, title, description, action, ...props }) {
                return (
                    <Toast className='flex items-start justify-start' key={id} {...props}>
                        <span className='w-fit text-xl opacity-60'>{getIcon(props.variant)}</span>
                        <div className='grid gap-1'>
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && <ToastDescription>{description}</ToastDescription>}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                );
            })}
            <ToastViewport />
        </ToastProvider>
    );
}

type Variant = 'success' | 'error' | 'warning' | 'info' | 'default' | 'destructive';

const getIcon = (variant: Variant) => {
    switch (variant) {
        case 'success':
            return <FaCheckCircle />;
        case 'error':
            return <FaCircleXmark />;
        case 'warning':
            return <FaExclamationTriangle />;
        case 'info':
            return <FaInfoCircle />;
        default:
            return '';
    }
};
