import Roles from '@/constants/Roles';
import Routes from '@/constants/Routes';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useMemo } from 'react';

const useAuth = () => {
    const {
        user: { user, session },
        checkSession,
        isLoading,
        error,
    } = useUser() as any;
    const { role, accessToken } = session;
    const handleLogout = () => {
        window.location.href = Routes.LOGOUT;
    };

    return useMemo(
        () => ({
            user,
            update: checkSession,
            isLoading,
            error,
            accessToken,
            isAuthenticated: Boolean(accessToken),
            role: role,
            isAdmin: role === Roles.ADMIN,
            logout: handleLogout,
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accessToken, checkSession, error, isLoading, role, user]
    );
};

export default useAuth;
